import * as React from "react"
import { FC, useState } from "react"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import styled from "styled-components"
import { Link } from "gatsby"
import EnquiryForm from "../social-supermarket/components/landing-page/new/components/lead-capture/enquiry-form/EnquiryForm"
import { largeDesktop } from "../social-supermarket/constants/screenSizes"

const Container = styled.div`
  padding: 40px 0 100px;
  max-width: 800px;
  margin: auto;

  @media (max-width: ${largeDesktop}px) {
    padding: 40px 10px 100px;
  }
`
const Select = styled.select`
  margin-bottom: 50px;
`

interface Props {}

const toMap = {
  order: "support@socialsupermarket.org",
  corporate: "info@socialsupermarket.org",
  partnership: "hello@socialsupermarket.org",
  general: "hello@socialsupermarket.org",
}

const Contact: FC<Props> = ({}): JSX.Element => {
  const [type, setTo] = useState<string>("")

  const handleChange = e => {
    setTo(e.target.value)
  }

  return (
    <Layout>
      <SEO title={"Contact"} description={"Contact social supermarket"} />
      <Container>
        <h1>Get In Touch</h1>
        <p>
          To help us respond as quickly as possible please select your reason for getting in touch.
        </p>
        <p>
          If you are a prospective supplier please apply via the following page:{" "}
          <Link to={"/who-do-we-work-with"}>Brands we work with</Link>.
        </p>
        <Select placeholder={"Please select"} value={type} onChange={handleChange}>
          <option disabled value={""}>
            Please select
          </option>
          <option value={"order"}>Help regarding an existing order</option>
          <option value={"corporate"}>Corporate enquiry</option>
          <option value={"partnership"}>Brand enquiry</option>
          <option value={"general"}>General enquiry</option>
        </Select>
        {type && type !== "order" && (
          <EnquiryForm
            source={"Inbound Enquiry"}
            name={type === "corporate" ? "GeneralCorporateEnquiryForm" : "GeneralEnquiryForm"}
            to={toMap[type]}
            isMql={type === "corporate"}
          />
        )}
        {type === "order" && (
          <div>
            Please contact support@socialsupermarket.org referencing your order number if you have
            it.
          </div>
        )}
      </Container>
    </Layout>
  )
}

export default Contact
